function Footer () {
  return (
    <>
      <div className='fixed-content-footer'>
        <div className='social-links-2'>
          <div className='social-links'>
            <a
              className='social-links__anchor'
              href='https://www.facebook.com/yetiecosystem'
              target='_blank'
              rel='noreferrer'
            >
              <img
                className='social-links__image'
                src='/socials/facebook.svg'
                alt='Facebook logo'
                width='25'
                height='25'
              />
            </a>
            <a
              className='social-links__anchor'
              href='https://twitter.com/YetiEcosystem'
              target='_blank'
              rel='noreferrer'
            >
              <img
                className='social-links__image'
                src='/socials/twitter.svg'
                alt='Twitter logo'
                width='25'
                height='25'
              />
            </a>
            {/* <a
              className='social-links__anchor'
              href='https://www.instagram.com/yeti.ecosystem/'
              target='_blank'
              rel='noreferrer'
            >
              <img
                className='social-links__image'
                src='/socials/instagram.svg'
                alt='Instagram logo'
                width='25'
                height='25'
              />
            </a> */}
            <a
              className='social-links__anchor'
              href='https://t.me/yetiecosystem'
              target='_blank'
              rel='noreferrer'
            >
              <img
                className='social-links__image'
                src='/socials/telegram.svg'
                alt='Telegram logo'
                width='25'
                height='25'
              />
            </a>
            {/* <a
              className='social-links__anchor'
              href='https://www.youtube.com/channel/UCoztSNQ7o8hIM-rLffFyMfw'
              target='_blank'
              rel='noreferrer'
            >
              <img
                className='social-links__image'
                src='/socials/youtube.svg'
                alt='Youtube logo'
                width='25'
                height='25'
              />
            </a> */}
            <a
              className='social-links__anchor'
              href='https://www.reddit.com/user/yeti_coin/?sort=hot'
              target='_blank'
              rel='noreferrer'
            >
              <img
                className='social-links__image'
                src='/socials/reddit.svg'
                alt='Reddit logo'
                width='25'
                height='25'
              />
            </a>
          </div>
        </div>
        <div className='fixed-content-footer__copy'>
          <span className='footer-copy__date'>{`© ${new Date().getFullYear()} `}</span>
          <span className='footer-copy__name'>YETI Coin</span>
        </div>
      </div>
    </>
  )
}

export default Footer
